<template>
  <div class="profile-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="edit-con button-reg">
      <b-button pill class="asmt-bt my-btn" size="lg" @click="backToProfile()">
        <span class="primary-color btn-text">Back To My Profile</span>
      </b-button>
    </div>
    <div class="main-con">
      <MyProfile />
    </div>
    <div class="edit-pr-con">
      <SearchUser :user="userList" @userSelected="userSelected($event)"></SearchUser>
      <div class="recent-users">Recently added users</div>
      <b-table id="edit-profile" class="gn-table-sm" :items="userList" :fields="fields">
        <template #cell(actions)="row">
          <b-button class="update-bt" pill variant="outline-primary" @click="userSelected(row.item.email)">
            Edit
          </b-button>
        </template>
        <template #cell(title)="row">
          <span v-for="(title, index) in row.item.title" :key="index">
            <span>{{ title }}</span><span v-if="index != row.item.title.length - 1">, </span>
          </span>
        </template>
      </b-table>
      <div v-if="userList.length == 0" class="no-data no-rec">
        <img alt="no data" src="../../assets/No_Data.svg" />
        <div class="ed-date">No Records Found</div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Loader from "../../components/common/Loader.vue";
import MyProfile from "./MyProfile.vue";
import SearchUser from "./SearchUser.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import Role from "../../common/constants/roles"
export default {
  name: "EditUserHome",
  data() {
    return {
      roles: Role,
      apiCount: 0,
      showLoader: true,
      selectedUSer: "",
      fields: [
        {
          key: "userName",
          sortable: false,
          label: "User Name",
        },
        {
          key: "title",
          sortable: false,
          label: "Title",
        },
        {
          key: "email",
          sortable: false,
          label: "Email",
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      userList: [],
      serachResult: [],
      selectedUSerId: "",
    };
  },
  components: {
    Breadcrumb,
    Loader,
    MyProfile,
    SearchUser,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 0,
      subMenu: 3,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Edit User Profile",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "My Profile / ", primary: true, redirect: "/profile" },
        { name: "Edit User Profile", primary: false },
      ],
    });
  },
  created() {
    if (
      !(
        this.$oidc.userProfile.roles.includes(this.roles.CORPORATE) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(
          this.roles.REGIONALPILLARLEADER
        ) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER)||
        this.$oidc.userProfile.roles.includes(this.roles.POENERGYADMIN)||
        this.$oidc.userProfile.roles.includes(this.roles.CODELELVEADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.FUNCTIONALADMIN) 
      )
    ) {
      this.$router.push("/profile");
      return;
    }
    this.getListOfUsers();
  },
  methods: {
    userSelected(id) {
      console.log(id);
      this.$router.push("edit-user/" + id);
    },
    backToProfile() {
      this.$router.push("profile");
    },
    getListOfUsers() {
      console.log(Api.GETLISTOFUSERS);
      ApiCalls.httpCallDetails(Api.GETLISTOFUSERS, "get").then((data) => {
        this.apiCount++;
        this.userList = data.data;
        if (this.apiCount == 1) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
      console.log("this.userList ", this.userList);
    },
  },
};
</script>

<style scoped>
.edit-pr-con {
  padding: 2rem;
  background: #fff;
}

.search-box {
  height: 4rem;
  width: 30%;
  border: 1px solid #313131;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

.search-box:focus {
  border: 2px solid #274ba7;
}

.search-box:focus+.user-lable {
  display: block;
}

.recent-users {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.update-bt {
  background-color: #eff3ff !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
  width: 5rem;
}

.update-bt:hover {
  background-color: #e3e8f7 !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
}

.user-lable {
  font-size: 1rem;
  font-family: "BarlowM", sans-serif;
}

.no-data {
  text-align: center;
}

.serach-icon {
  font-size: 1.5rem;
  position: absolute;
  left: 27%;
  top: 1.2rem;
}

.search-con {
  position: relative;
}

.sh-user-con {
  width: 29%;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 7px;
  position: absolute;
  z-index: 10;
}

.sh-user-list {
  padding: 1rem;
  border-bottom: 1px solid #f5efef;
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
  cursor: pointer;
}

.edit-con {
  position: absolute;
  top: 8rem;
  right: 4.5rem;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: transparent !important;
  color: #274ba7 !important;
  height: 3.5rem;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.no-rec {
  text-align: center;
  padding: 1rem;
}
</style>